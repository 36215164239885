<template>
  <div>
    <!-- 组织架构 -->
    <div class="box">
      <div class="boxleft">
        <el-table
          :data="menuTableData"
          style="width: 100%"
          row-key="departmentId"
          border
          default-expand-all
          :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
        >
          <el-table-column
            prop="departmentName"
            label="部门"
            sortable
            width="180"
          >
          </el-table-column>
          <el-table-column fixed="right" label="操作">
            <template slot-scope="scope">
              <el-button type="text" size="small">
                <span
                  v-if="hasPermiss('zjck_zhuJiJiaGou:add')"
                  class="tablebut"
                  @click="menuadd(scope.row)"
                  >添加</span
                >

                <span
                  v-if="hasPermiss('zjck_zhuJiJiaGou:update')"
                  class="tablebut"
                  @click="menumodify(scope.row.departmentId)"
                  >修改</span
                >
                <span
                  v-if="hasPermiss('zjck_zhuJiJiaGou:del')"
                  class="tablebut"
                  @click="delBtns(scope.row)"
                  >删除</span
                >

                <span
                  v-if="
                    scope.row.isDisable == 0 &&
                    hasPermiss('zjck_yuanGongGuanLi:disable')
                  "
                  class="tablebut"
                  @click="isAbleBtns(scope.row, 1)"
                  >禁用</span
                >
                <span
                  v-if="
                    scope.row.isDisable == 1 &&
                    hasPermiss('zjck_zhuJiJiaGou:enable')
                  "
                  class="tablebut"
                  style="color: #999"
                  @click="isAbleBtns(scope.row, 0)"
                  >启用</span
                >
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="boxright">
        <div>
          <!-- 按钮列表  -->
          <div class="btnList1" ref="btnList">
            <el-button
              class="itemBtn btnColor"
              v-if="hasPermiss('zjck_zhuJiJiaGou:add')"
              @click="menuadd"
              >新建</el-button
            >
            <!-- <el-button class="itemBtn btnColor" @click="apply">新建</el-button> -->
            <!-- <el-button class="itemBtn btnColor" @click="CancelPayment"
              >删除</el-button
            >
            <el-button class="itemBtn btnColor" @click="modifybut"
              >修改</el-button
            > -->

            <el-button class="itemBtn btnColor" @click="myModel"
              >列表设置</el-button
            >
            <el-dropdown @command="handleCommand_dc">
              <el-button class="dropdownBtn">
                导出<i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="dc">导出</el-dropdown-item>
                <el-dropdown-item command="dcmb">下载导出模板</el-dropdown-item>
                <el-dropdown-item command="scmb"
                  >保存(上传)模板</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <!-- 查询条件 -->
          <el-form :model="queryData">
            <div
              ref="queryCriteria"
              class="HeadInputbox"
              :class="{ HeadInputbox1: judge, active: isActive }"
            >
              <div class="item_right">
                <div class="singleinp">
                  <div class="singleinpleft">部门名称:</div>
                  <div class="singleinpright">
                    <el-input
                      v-model="queryData.departmentName"
                      clearable
                      placeholder="请输入"
                    ></el-input>
                    <!-- <el-select
                      class="queryInput"
                      v-model="queryData.departmentName"
                      filterable
                      clearable
                      placeholder="请选择"
                    >
                      <el-option
                        v-for="item in cangkuList"
                        :key="item.id"
                        :label="item.storageName"
                        :value="item.storageName"
                      >
                      </el-option>
                    </el-select> -->
                  </div>
                </div>
                <div class="singleinpbtn">
                  <el-button
                    class="seniorbut"
                    @click="queryBtn_ok"
                    icon="el-icon-search"
                    >查询</el-button
                  >
                  <!-- <el-tooltip content="查询项恢复初始状态" placement="top">
                  <div class="condition" @click="queryBtn_cz">清除</div>
                </el-tooltip>
                <el-tooltip content="查询更多条件" placement="top">
                  <div class="condition" @click="more">
                    {{ MoreConditions }}
                  </div>
                </el-tooltip> -->
                </div>
              </div>
              <!-- <div class="item_left">
          <div class="singleinpbtn">
            <el-button
              class="seniorbut"
              @click="queryBtn_ok"
              icon="el-icon-search"
              >查询</el-button
            >
            <el-tooltip content="查询项恢复初始状态" placement="top">
              <div class="condition" @click="queryBtn_cz">清除</div>
            </el-tooltip>
            <el-tooltip content="查询更多条件" placement="top">
              <div class="condition" @click="more">
                {{ MoreConditions }}
              </div>
            </el-tooltip>
          </div>
        </div> -->
            </div>
          </el-form>

          <!-- 表格 -->
          <div
            class="tableBox"
            ref="tableWrapper"
            :class="{ tableBox: TableStyle, tableBox1: fullscreen }"
          >
            <el-table
              :height="tableHeigth"
              ref="mytable"
              :data="tableData"
              :row-class-name="tableRowClassName"
              highlight-current-row
              :header-cell-style="{ background: '#f0f0f0' }"
              border
              @selection-change="handleSelectionChange"
            >
              <el-table-column
                fixed="left"
                label="序号"
                type="index"
                width="60"
              >
              </el-table-column>
              <el-table-column fixed="left" type="selection" width="60">
              </el-table-column>
              <template v-for="(item, index) in myTableHeard">
                <el-table-column
                  :key="index"
                  :label="item.name"
                  :prop="item.field"
                  sortable
                  :min-width="item.width"
                  :data-isTotal="item.isTotal"
                  v-if="item && item.isShow"
                >
                </el-table-column>
              </template>
            </el-table>
          </div>

          <!-- //排序 -->
          <div>
            <el-dialog
              title="自定义表头排序"
              :visible.sync="show_sortTableHeard"
              append-to-body
              width="40%"
              center
            >
              <div>
                <h5 style="margin: 0">上下拖动可以排序,需要显示的列请打钩√</h5>
                <div class="titles_dialog">
                  <el-checkbox
                    label="全选"
                    v-model="allCheck"
                    @change="checkAll"
                  >
                  </el-checkbox>
                  <div>
                    <span>模板名称 : </span>
                    <input class="mdTitName" type="text" v-model="sortName" />
                  </div>
                  <div v-if="tabelHeadeTitle">
                    {{ selectLength }} / {{ tabelHeadeTitle.length || 0 }}
                  </div>
                </div>
                <draggable v-model="tabelHeadeTitle">
                  <transition-group>
                    <div
                      class="daraStyle"
                      v-for="(element, index) in tabelHeadeTitle"
                      :key="element.field"
                    >
                      <el-checkbox
                        class="rowChecks"
                        :label="element.id"
                        v-model="element.isShow"
                        @change="checktab(index, $event)"
                      >
                        {{ element.name }}
                      </el-checkbox>
                      <div class="cloWidth">
                        <div class="cloLabel">列宽:</div>
                        <el-input
                          class="cloInput"
                          type="number"
                          v-model="element.width"
                        ></el-input>
                      </div>
                      <div class="daraNum">{{ element.sort }}</div>
                    </div>
                  </transition-group>
                </draggable>
              </div>
              <div slot="footer" class="dialog-footer">
                <el-button @click="cancelSortHeard">取 消</el-button>
                <el-button
                  @click="confirmOk"
                  type="primary"
                  style="margin-left: 50px"
                  >确 定</el-button
                >
              </div>
            </el-dialog>
            <el-dialog
              title="新建"
              :visible.sync="NewWarehouse"
              :before-close="WarehouseClose"
            >
              <div class="newForm">
                <el-form label-width="120px">
                  <div class="queryItem1">
                    <el-form-item label="仓库编号:">
                      <el-input
                        v-model="Warehousearray.number"
                        :disabled="NumberStatus"
                      ></el-input>
                    </el-form-item>
                  </div>
                  <div class="queryItem1">
                    <el-form-item label="仓库名称:">
                      <el-input v-model="Warehousearray.storageName"></el-input>
                    </el-form-item>
                  </div>
                  <div class="SingleChoicebox">
                    <el-checkbox v-model="Warehousearray.isDefaultStorage"
                      >是否默认仓库</el-checkbox
                    >
                  </div>
                  <div class="queryItem1">
                    <el-form-item label="仓库电话:">
                      <el-input
                        v-model="Warehousearray.receivingPhone"
                      ></el-input>
                    </el-form-item>
                  </div>
                  <div class="queryItem1">
                    <el-form-item label="排序">
                      <el-input-number
                        v-model="Warehousearray.sort"
                        controls-position="right"
                        :min="1"
                        :max="100000"
                      ></el-input-number>
                    </el-form-item>
                  </div>
                  <div class="queryItem1">
                    <el-form-item label="仓库地址:">
                      <el-input
                        v-model="Warehousearray.receivingAddress"
                      ></el-input>
                    </el-form-item>
                  </div>
                  <div class="queryItem1">
                    <el-form-item label="归属部门:">
                      <el-input v-model="Warehousearray.postCode"></el-input>
                    </el-form-item>
                  </div>
                  <div class="queryItem1">
                    <el-form-item label="备注:">
                      <el-input
                        type="textarea"
                        :rows="1"
                        v-model="Warehousearray.comment"
                      ></el-input>
                    </el-form-item>
                  </div>
                </el-form>
              </div>
              <div class="footerbox">
                <el-button class="seniorbut" @click="PaymentConfirmation"
                  >保存</el-button
                >
              </div>
            </el-dialog>
          </div>

          <div>
            <el-dialog
              title="上传编辑好的导出模板"
              :visible.sync="show_upload"
              append-to-body
              width="40%"
              center
            >
              <div>
                <el-upload
                  :action="uploadUrl"
                  :show-file-list="true"
                  :file-list="fileList"
                  :limit="1"
                  :http-request="uploadSectionFile"
                >
                  <el-button size="small" type="primary">点击上传</el-button>
                  <div slot="tip" class="el-upload__tip">上传模板</div>
                </el-upload>
              </div>
              <div slot="footer" class="dialog-footer">
                <el-button @click="show_upload = false">关 闭</el-button>
              </div>
            </el-dialog>
          </div>
        </div>
        <!-- 分页组件 -->
        <paging
          ref="pags"
          :pageNum="pageNum"
          :total="total"
          :sizeList="sizeList"
          :size="size"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
        ></paging>
      </div>
    </div>
    <!-- 新建修改部门 -->
    <el-dialog
      :title="PopUpTitle"
      :visible.sync="menushowBOX"
      @close="closeDia"
    >
      <div class="newForm">
        <el-form :model="CalculationRules" label-width="120px" :rules="rules">
          <!-- <div class="queryItem1" v-if="PopUpTitle == '修改部门'">
            <el-form-item label="部门编号:">
              <el-input
                readonly
                v-model="CalculationRules.departmentNumber"
              ></el-input>
            </el-form-item>
          </div> -->
          <div class="queryItem1">
            <el-form-item label="部门名称:" prop="departmentName">
              <el-input v-model="CalculationRules.departmentName"></el-input>
            </el-form-item>
          </div>
          <div class="queryItem1">
            <el-form-item label="上级部门:" prop="parentId">
              <el-select
                clearable
                filterable
                v-model="CalculationRules.parentId"
              >
                <el-option
                  v-for="(item, index) in bumenList"
                  :key="index"
                  :label="item.departmentName"
                  :value="item.departmentId"
                ></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="queryItem1">
            <el-form-item label="部门类型:">
              <el-select
                v-model="CalculationRules.organizationDepartmentTypeId"
                filterable
                clearable
              >
                <el-option
                  v-for="(itme, index) in bumenTypeList"
                  :key="index"
                  :label="itme.typeName"
                  :value="itme.typeId"
                ></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="queryItem1">
            <el-form-item label="排序:" prop="sort">
              <el-input v-model="CalculationRules.sort"></el-input>
            </el-form-item>
          </div>
          <div class="queryItem1">
            <el-form-item label="备注:">
              <el-input
                v-model="CalculationRules.comment"
                type="textarea"
                :rows="2"
              ></el-input>
            </el-form-item>
          </div>
        </el-form>
      </div>
      <div class="footerbox">
        <el-button class="seniorbut" @click="confirmbut">确认 </el-button>
      </div>
    </el-dialog>
  </div>
</template>


<script>
import { apiList, Api } from "@/assets/js/api";
import tools from "@/assets/js/tool";
import draggable from "vuedraggable"; //拖拽组件
import paging from "@/components/pagings.vue";
import screenfull from "screenfull";
import tool from "@/assets/js/tool";

export default {
  inject: ["reload"],
  components: {
    // mytables,
    draggable,
    paging,
  },
  data() {
    return {
      bumenList: [], //上级部门下拉框列表
      bumenTypeList: [], //部门类型
      CalculationRules: {
        departmentNumber: "",
        parentId: "", //上级部门value
        departmentName: "", //部门名称
        sort: "1", //菜单排序
        comment: "", //
        organizationDepartmentTypeId: "", //
      },
      rules: {
        departmentName: [
          { required: true, message: "请输入", trigger: "blur" },
        ],
        parentId: [{ required: true, message: "请输入", trigger: "blur" }],
        sort: [{ required: true, message: "请输入", trigger: "blur" }],
      },
      PopUpTitle: "", //弹窗标题
      menushowBOX: false, //菜单弹窗
      menuTableData: [],
      //\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\

      queryData: {
        departmentName: "",
      },

      // ================
      pageNum: 1, //页码
      total: 0, //总条数
      sizeList: [10, 20, 50, 100, 200],
      size: 50, //一页几条
      nextPage: false, //是否有下一页
      show_sortTableHeard: false, //显示弹窗
      show_upload: false, //显示上传弹窗
      myTableHeard: [], //我的表头
      originalTabelHeadeTitle: [],
      selectLength: 0, //显示的长度
      allCheck: false, //全选
      tableHeigth: "50vh",
      tabelHeadeTitle: [
        {
          name: "部门名称",
          field: "departmentName",
          sort: 1,
          isShow: true,
          isTotal: false,
          width: "120",
        },
        {
          name: "上级部门",
          field: "parentName",
          sort: 1,
          isShow: true,
          isTotal: false,
          width: "120",
        },
        {
          name: "是否禁用",
          field: "isDisableShow",
          sort: 1,
          isShow: true,
          isTotal: false,
          width: "100",
        },
        {
          name: "新建时间",
          field: "createTime",
          sort: 1,
          isShow: true,
          isTotal: false,
          width: "160",
        },
        {
          name: "修改时间",
          field: "updateTime",
          sort: 1,
          isShow: true,
          isTotal: false,
          width: "160",
        },
      ],
      tableData: [],
      totalArr: [], //需要合计的字段
      className: "", //导出需要
      fieldName: [], //下载字段名
      head: [], //下载表头字段
      fileList: [],
      uploadUrl: "", //上传路径
      sortName: "模板名", //排序名
      pageGroupName: "cngkuguanli", //页面标识
      selectTableData: [], //选中的数据
      //===============
      pageNum_member: 1, //页码
      isActive: true, //输入框绑定动态class
      judge: false, //输入框绑定动态class
      MoreConditions: "更多条件", //输入框绑定动态class
      fullscreen: false,
      TableStyle: true,
      cangkuList: [], //仓库下拉列表
      /*       yunshuList: [], //运输方式列表
      shengheList: [], //审核状态列表
      qudaoList: [], //渠道列表
      qudaodailiList: [], //渠道代理列表 */
      NewWarehouse: false, //新建修改仓库弹窗
      pageType: "", //修改删除状态

      Warehousearray: {
        //仓库列表
        isDefaultStorage: "", //是否默认仓库0=否1=是
        number: "", //仓库编号
        postCode: "", //邮政编码
        receivingAddress: "", //收货地址
        receivingPhone: "", //收货电话
        sort: "", //排序值
        storageName: "", //仓库名称
        comment: "", //备注
        id: "", //	仓库id(修改时必传)
      },
      NumberStatus: false, //编号状态(是否禁用)
      deperIds: [],
    };
  },
  updated() {
    if (this.show_sortTableHeard) {
      // 排序表头
      let arr = this.tabelHeadeTitle;
      for (var i = 0; i < arr.length; i++) {
        arr[i].sort = i + 1;
      }
      this.tabelHeadeTitle = arr;
    }
  },
  created() {
    this.uploadUrl = this.common.uploadUrl;
    if (this.originalTabelHeadeTitle.length == 0) {
      this.originalTabelHeadeTitle = JSON.parse(
        JSON.stringify(this.tabelHeadeTitle)
      );
    }
    this.myTableHeard = this.tabelHeadeTitle;
    this.getGroupFindFieldSort(); //获取用户自定义表头

    this.endtime = tools.getCurrentDate(); //今天的时间;
    // this.endtime = tools.getFlexDate(1); //今天+1的时间;
    this.sarttime = tools.getFlexDate(-3); //三天前
    this.times = [this.sarttime, this.endtime];

    this.departmentHierarchy();
  },
  mounted() {
    const that = this;
    // this.getList();
    this.getData();
    this.getStatusValList("finance_in_record_v1.approve_status") || []; //审核状态列表
    this.getdepartmentTypeList();
  },
  watch: {},
  methods: {
    // 禁用部门
    isAbleBtns(data, type) {
      this.deperIds = []; //先清空之前的数据
      console.log(data);
      let str = "启用";
      if (type == 1) {
        str = "禁用";
      }
      let dpIds = this.getDpIds(data) || [];
      this.$confirm("是否" + str + "部门: " + data.departmentName, "提示:")
        .then(() => {
          let param = {
            departmentId: dpIds,
            isDisable: type, //0==启用,1==禁用
          };
          let sign = tools.getSign(param);
          param.sign = sign;
          Api.departmentDisable(param).then((res) => {
            if (res.data.status == "success") {
              this.$message.success(res.data.message || "操作成功");
              this.departmentHierarchy();
              this.getData();
            }
          });
        })
        .catch(() => {});
    },
    // 递归获取部门下所有id
    getDpIds(data) {
      let ids = this.deperIds || [];
      ids.push(data.departmentId);
      let arrs = data.children || [];
      for (var i = 0; i < arrs.length; i++) {
        ids.push(arrs[i].departmentId);
        let arr2 = arrs[i].children || [];
        for (var j = 0; j < arr2.length; j++) {
          this.getDpIds(arr2[j]);
        }
      }
      this.deperIds = ids;
      return this.deperIds;
    },
    // 部门类型下拉列表
    getdepartmentTypeList() {
      Api.getdepartmentTypeList().then((res) => {
        if (res.data.status == "success") {
          let arrs = res.data.result || [];
          this.bumenTypeList = arrs;
        }
      });
    },
    // 部门下拉列表
    departmentChooseList() {
      Api.departmentChooseList().then((res) => {
        if (res.data.status == "success") {
          let bumenArr = res.data.result || [];
          bumenArr.push({ departmentId: "-1", departmentName: "无上级" });
          this.bumenList = bumenArr;
        }
      });
    },
    // 部门层级列表
    departmentHierarchy() {
      Api.departmentHierarchy().then((res) => {
        if (res.data.status == "success") {
          let bmList = res.data.result || [];
          this.menuTableData = this.diGuiGetMeun(bmList, "-1");
        }
      });
    },
    //递归获取数据
    diGuiGetMeun(data, parentId) {
      let tree = [];
      let temp;
      data.forEach((item) => {
        if (item.parentId == parentId) {
          let obj = item;
          obj.departmentName = item.departmentName;
          obj.children = [];
          temp = this.diGuiGetMeun(data, item.departmentId);
          if (temp.length > 0) {
            obj.children = temp;
          }
          tree.push(obj);
        }
      });
      //排序
      for (var i = 0; i < tree.length - 1; i++) {
        for (var j = 0; j < tree.length - 1 - i; j++) {
          if (tree[j].sort > tree[j + 1].sort) {
            let temp = tree[j + 1];
            tree[j + 1] = tree[j];
            tree[j] = temp;
          }
        }
      }
      return tree;
    },
    //菜单新建
    menuadd(row) {
      this.PopUpTitle = "新建部门";
      this.menushowBOX = true;
      this.departmentChooseList();
    },
    //菜单修改
    menumodify(id) {
      this.PopUpTitle = "修改部门";
      this.menushowBOX = true;
      Api.departmentDetail({ departmentId: id }).then((res) => {
        if (res.data.status == "success") {
          let datas = res.data.result || this.CalculationRules;
          this.CalculationRules = datas;
          this.departmentChooseList();
        }
      });
    },
    // 删除
    delBtns(data) {
      this.$confirm("是否删除该部门?", "提示:")
        .then(() => {
          console.log(data);
          let param = {
            departmentId: data.departmentId,
          };
          let sign = tools.getSign(param);
          param.sign = sign;
          Api.deleteDepartment(param).then((res) => {
            if (res.data.status == "success") {
              this.$message.success(res.data.message || "操作成功");
              this.departmentHierarchy();
              this.getData();
            }
          });
        })
        .catch(() => {});
    },
    closeDia() {
      this.CalculationRules = {
        departmentNumber: "",
        parentId: "", //上级部门value
        departmentName: "", //部门名称
        sort: "1", //菜单排序
        comment: "", //
        organizationDepartmentTypeId: "", //
      };
    },
    //添加修改菜单确认
    confirmbut() {
      let param = {
        departmentName: this.CalculationRules.departmentName,
        parentId: this.CalculationRules.parentId || "-1",
        sort: this.CalculationRules.sort,
        comment: this.CalculationRules.comment,
        // departmentNumber: this.CalculationRules.departmentNumber,
        organizationDepartmentTypeId:
          this.CalculationRules.organizationDepartmentTypeId,
      };
      if (this.PopUpTitle == "新建部门") {
        let sign = tools.getSign(param);
        param.sign = sign;
        Api.addDepartment(param).then((res) => {
          if (res.data.status == "success") {
            this.$message.success(res.data.message || "操作成功");
            this.menushowBOX = false;
            this.departmentHierarchy();
            this.getData();
          }
        });
      } else {
        // 修改
        param.departmentId = this.CalculationRules.departmentId;
        let sign = tools.getSign(param);
        param.sign = sign;
        Api.updateDepartment(param).then((res) => {
          if (res.data.status == "success") {
            this.$message.success(res.data.message || "操作成功");
            this.menushowBOX = false;
            this.departmentHierarchy();
            this.getData();
          }
        });
      }
    },

    //仓库弹窗关闭
    WarehouseClose() {
      this.Warehousearray = {
        //仓库列表
        isDefaultStorage: "", //是否默认仓库0=否1=是
        number: "", //仓库编号
        postCode: "", //邮政编码
        receivingAddress: "", //收货地址
        receivingPhone: "", //收货电话
        sort: "", //排序值
        storageName: "", //仓库名称
        comment: "", //备注
        id: "", //	仓库id(修改时必传)
      };
      this.NewWarehouse = false;
    },
    //新建仓库
    apply() {
      this.NumberStatus = false;
      this.pageType = "add";
      this.NewWarehouse = true;
    },
    //修改
    modifybut() {
      this.NumberStatus = true;
      this.pageType = "modify";
      let selectTableData = this.selectTableData;
      if (selectTableData.length != 1) {
        this.$message.warning("请勾选一条数据");
      } else {
        //console.log(selectTableData[0])

        if (selectTableData[0].isDefaultStorage == 0) {
          selectTableData[0].isDefaultStorage = false;
        } else {
          selectTableData[0].isDefaultStorage = true;
        }
        this.Warehousearray = selectTableData[0];
        this.NewWarehouse = true;
      }
    },
    //新建修改保存
    PaymentConfirmation() {
      //新建
      let _this = this;
      let Warehousearray = this.Warehousearray;
      if (Warehousearray.isDefaultStorage == true) {
        Warehousearray.isDefaultStorage = 1;
      } else {
        Warehousearray.isDefaultStorage = 0;
      }
      let parm = {
        isDefaultStorage: Warehousearray.isDefaultStorage,
        number: Warehousearray.number,
        postCode: Warehousearray.postCode,
        receivingAddress: Warehousearray.receivingAddress,
        receivingPhone: Warehousearray.receivingPhone,
        sort: Warehousearray.sort,
        storageName: Warehousearray.storageName,
        comment: Warehousearray.comment,
        id: Warehousearray.id,
      };
      let sign = tool.getSign(parm);
      Warehousearray.sign = sign;
      if (this.pageType == "add") {
        Api.addStorageV1(Warehousearray).then((res) => {
          if (res.data.status == "success") {
            this.$message.success(res.data.message || "操作成功");
            this.NewWarehouse = false;
            _this.getData();
          }
        });
      }
      //修改
      else {
        Api.updateStorageV1(Warehousearray).then((res) => {
          if (res.data.status == "success") {
            this.$message.success(res.data.message || "操作成功");
            this.NewWarehouse = false;
            _this.getData();
          }
        });
      }
    },
    //删除
    CancelPayment() {
      let _this = this;
      let selectTableData = this.selectTableData;
      if (selectTableData.length != 1) {
        this.$message.warning("请勾选一条数据");
      } else {
        let storageId = selectTableData[0].id;
        //console.log(selectTableData);
        Api.delStorageV1({ storageId: storageId }).then((res) => {
          if (res.data.status == "success") {
            this.$message.success(res.data.message || "操作成功");
            _this.getData();
          }
        });
      }
    },
    //获取下拉列表
    getList() {
      //获取仓库下拉列表
      Api.getStorageList().then((res) => {
        this.cangkuList = res.data.result || [];
      });
    },

    //表格样式i
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 === 0) {
        return "warning-row";
      }
    },
    // 获取状态展示值
    getStatusValList(status) {
      // 包裹状态: ==========> package_v1.package_status
      // 拒收单拒收状态: ====> bill_rejection_v1.handle_status
      // 验货单验货状态: ====> bill_inspection_v1.check_status
      // 审批状态:==========> finance_in_record_v1.approve_status
      // 核销状态:==========> finance_in_record_v1.writeoff_status
      // 单据类型:==========> finance_in_record_v1.document_status
      // 打包状态:==========> bill_pack_v1.pack_status
      // 服务类型:==========> item_category_v1.service_status
      // 费项类型:==========> item_service_v1.direction_status
    },

    handleCommandTabs(m) {
      if (m == "a") {
        this.myModel();
      } else if (m == "b") {
        this.exportBtn();
      } else if (m == "c") {
        this.exportTemplateDownload();
      } else if (m == "d") {
        this.upLoadBtn();
      } else {
        this.screen();
      }
    },

    //输入框绑定动态class
    more() {
      this.isActive = !this.isActive;
      this.judge = !this.judge;
      this.fetTableHeight();
      if (this.judge == false) {
        this.MoreConditions = "更多条件";
      } else {
        this.MoreConditions = "收起条件";
      }
    },

    // 表格选择事件
    handleSelectionChange(e) {
      this.selectTableData = e;
    },

    /**
     * =========================按钮部分的方法=>开始??????????????????????????????????
     */

    //导出操作
    handleCommand_dc(command) {
      if (command == "dc") {
        // 导出
        this.exportBtn();
      } else if (command == "dcmb") {
        // 下载模板
        this.exportTemplateDownload();
      } else if (command == "scmb") {
        // 上传模板
        this.upLoadBtn();
      }
    },

    /**
     * =========================按钮部分的方法=>结束??????????????????????????????????
     */

    // ===============================================导出部分=开始======
    // 上传导出模板
    uploadSectionFile(param) {
      const file = param.file,
        fileName = file.name;

      // 根据后台需求数据格式
      const form = new FormData();
      // 文件对象
      form.append("file", file);
      // 本例子主要要在请求时添加特定属性，所以要用自己方法覆盖默认的action
      form.append("fileName", fileName);
      let signArr = [
        {
          key: "fileName",
          val: fileName,
        },
      ];
      let sign = tools.getMD5Sign(signArr);
      form.append("sign", sign);
      // 项目封装的请求方法，下面做简单介绍
      Api.uploadFile(form)
        .then((res) => {
          if (res.data.status == "success") {
            let fileName = res.data.result.fileName;

            let signArr = [
              {
                key: "groupName",
                val: this.pageGroupName,
              },
              {
                key: "templateName",
                val: this.sortName,
              },
              {
                key: "fileName",
                val: fileName,
              },
            ];
            let sign_ = tools.getMD5Sign(signArr);
            Api.saveExportTemplate({
              groupName: this.pageGroupName,
              templateName: this.sortName,
              fileName: fileName,
              sign: sign_,
            }).then((res) => {
              this.$message.success(res.data.message || "更新成功");
              this.show_upload = false;
            });
          }
        })
        .catch((err) => {});
    },

    // 下载用户导出模板
    exportTemplateDownload() {
      var fieldName = this.fieldName;
      var head = this.head;
      if (head.length == 0) {
        let fieldNames = [];
        let heads = [];
        for (var i = 0; i < this.myTableHeard.length; i++) {
          if (this.myTableHeard[i].isShow) {
            fieldNames.push(this.myTableHeard[i].field);
            heads.push(this.myTableHeard[i].name);
          }
        }
        fieldName = fieldNames;
        head = heads;
      }
      //加密数组格式
      let signArr = [
        {
          key: "fieldName",
          val: fieldName,
        },
        {
          key: "head",
          val: head,
        },
      ];
      let sign = tools.getMD5Sign(signArr);
      let param = {
        fieldName: fieldName,
        head: head,
        sign: sign,
      };
      Api.exportTemplateDownload(param).then((res) => {
        let downloadurl = res.data.result.fileUrl || "";
        //下载(这里其实是直接访问)
        var a = document.createElement("a");
        a.setAttribute("href", downloadurl);
        a.setAttribute("target", "_blank");
        a.click();
      });
    },
    // 真正导出
    templateDataExport(customizedExportId) {
      let that = this;
      let datas = that.tableData;
      let parm = {
        customizedExportId: customizedExportId, //模板id
        className: that.className,
        data: datas,
      };
      let signarr = tools.getSignArr(parm);
      let signs_e = tools.getMD5Sign(signarr);

      Api.templateDataExport({
        customizedExportId: customizedExportId,
        className: that.className,
        data: JSON.stringify(datas),
        sign: signs_e,
      }).then((res) => {
        //下载(这里其实是直接访问)
        let downloadurl = res.data.result.fileUrl;
        var a = document.createElement("a");
        a.style.display = "none";
        a.href = downloadurl;
        a.target = "_blank";
        a.setAttribute("download", "综合查询.xlsx"); //指定下载后的文件名，防跳转
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      });
    },
    // 上传按钮
    upLoadBtn() {
      this.show_upload = true;
    },
    // 点击导出按钮
    exportBtn() {
      let that = this;
      let fieldName = [];
      let head = [];
      for (var i = 0; i < this.myTableHeard.length; i++) {
        if (this.myTableHeard[i].isShow) {
          fieldName.push(this.myTableHeard[i].field);
          head.push(this.myTableHeard[i].name);
        }
      }
      this.fieldName = fieldName;
      this.head = head;

      // 1,先获取用户导出模板
      Api.getUserExportTemplate({
        groupName: this.pageGroupName,
      }).then((res) => {
        if (res.data.result && res.data.result[0].customizedExportId) {
          // 有自定义模板

          let customizedExportId = res.data.result[0].customizedExportId;
          that.templateDataExport(customizedExportId);
        } else {
          //没有自定义模板,就是下载一个
          tools.dynamicExport(
            this.myTableHeard,
            this.className,
            this.tableData,
            "其他支出列表"
          );
        }
      });
    },
    // ===============================================导出部分=结束======

    /**
     * ******************************计算表格高度**开始*************************
     */
    // 重置table高度
    resetHeight() {
      return new Promise((resolve, reject) => {
        this.tableHeigth = 0;
        resolve();
      });
    },
    // 设置table高度
    fetTableHeight() {
      this.resetHeight().then((res) => {
        this.tableHeigth = this.getHeight();
        //console.log(this.tableHeigth + "表格高度");
        this.$nextTick(() => {
          this.$refs.mytable.doLayout();
        });
      });
    },
    // 获取表格高度
    getHeight() {
      let windonHeight = document.body.clientHeight;
      let queryHeigth;
      let btnListHeight = this.$refs.btnList.offsetHeight + 16; // 按钮列表  16==上下的margin
      if (this.judge == true) {
        queryHeigth = this.$refs.queryCriteria.offsetHeight; //查询框
      } else {
        queryHeigth = 50;
      }
      let pagsHeigth = 40; //分页组件
      var otherHeight = queryHeigth + btnListHeight + pagsHeigth + 60 + 10; //60是顶部导航 ,10==预留空位
      return Math.floor(windonHeight - otherHeight) || "50vh"; //
    },
    /**
     * ******************************计算表格高度**结束*************************
     */

    // 刷新页面==>
    reload_s() {
      this.reload(); //App.Vue里面的方法
    },
    // 获取数据
    getData() {
      let formData = this.queryData;
      formData.pageStart = this.pageNum;
      formData.pageTotal = this.size;
      let param = {
        departmentName: this.queryData.departmentName,
        pageStart: this.queryData.pageNum,
        pageTotal: this.queryData.size,
      };
      Api.departmentList(param).then((res) => {
        //console.log(res.data.result.data);
        if (res.data.status == "success") {
          this.className = res.data.result.className || "";
          this.tableData = res.data.result.data || [];
          this.total = res.data.result.pageCount || 0;
          this.fetTableHeight();
        }
      });
    },
    // 查询按钮
    queryBtn_ok() {
      //console.log(this.queryData);
      this.pageNum = 1;
      this.getData();
    },
    // 重置按钮
    queryBtn_cz() {
      this.queryData = {
        storageName: "",
      };
    },

    // 获取自定义的排序,没有就用默认的
    getGroupFindFieldSort() {
      let that = this;
      Api.getGroupFindFieldSort({
        groupName: this.pageGroupName, //当前页面表格的标记(自定义字符串)
      }).then((res) => {
        let arr = res.data.result || [];
        if (arr.length > 0) {
          if (arr[0].sortObject) {
            that.myTableHeard = JSON.parse(arr[0].sortObject);
            this.sortName = arr[0].sortName;
          }
        } else {
          that.myTableHeard = that.tabelHeadeTitle;
        }
        let totalArr = [];
        //提取需要合计的字段
        for (var i = 0; i < that.myTableHeard.length; i++) {
          if (that.myTableHeard[i].isTotal) {
            totalArr.push(that.myTableHeard[i].field);
          }
        }
        that.totalArr = totalArr;
      });

      that.$nextTick(() => {
        that.$refs.mytable.doLayout();
      });
    },
    // 设置模板按钮
    myModel() {
      let that = this;
      that.show_sortTableHeard = true;
      that.tabelHeadeTitle = JSON.parse(JSON.stringify(that.myTableHeard));
      let selectLength = 0;
      for (var i = 0; i < that.tabelHeadeTitle.length; i++) {
        if (that.tabelHeadeTitle[i].isShow) {
          selectLength++;
        }
      }
      that.selectLength = selectLength;
      if (selectLength == that.tabelHeadeTitle.length) {
        that.allCheck = true;
      } else {
        that.allCheck = false;
      }
    },

    // 排序==>取消按钮
    cancelSortHeard() {
      this.show_sortTableHeard = false;
      this.$nextTick(() => {
        this.$refs.mytable.doLayout();
      });
    },
    // 排序==>确认按钮
    confirmOk() {
      this.myTableHeard = this.tabelHeadeTitle;
      let sortObject = this.myTableHeard;
      let signs = [
        {
          key: "sortName",
          val: this.sortName, //综合查询接口路径
        },
        {
          key: "groupName",
          val: this.pageGroupName,
        },
        {
          key: "sortObject",
          val: JSON.stringify(sortObject),
        },
      ];

      let sign = tools.getMD5Sign(signs);

      let param = {
        // apiPath: apiList.api_packageList, //综合查询接口路径
        groupName: this.pageGroupName, //当前页面表格的标记(自定义字符串)
        sortName: this.sortName, //综合查询接口路径
        sortObject: JSON.stringify(sortObject),
        sign: sign,
      };
      Api.pushFindFieldSort(param).then((res) => {
        this.$message.success(res.data.message || "操作成功");
        this.getGroupFindFieldSort();
      });
      this.show_sortTableHeard = false; //关闭弹窗
      this.$nextTick(() => {
        this.$refs.mytable.doLayout();
      });
      this.show_sortTableHeard = false;
    },
    // 点击某一项
    checktab(idx, e) {
      let that = this;
      that.tabelHeadeTitle[idx].isShow = e;
      let selectLength = 0;
      for (var i = 0; i < that.tabelHeadeTitle.length; i++) {
        if (that.tabelHeadeTitle[i].isShow) {
          selectLength++;
        }
      }
      that.selectLength = selectLength;
      if (selectLength == that.tabelHeadeTitle.length) {
        that.allCheck = true;
      } else {
        that.allCheck = false;
      }
    },
    //全选
    checkAll(e) {
      let that = this;
      this.allCheck = e;
      for (var j = 0; j < this.tabelHeadeTitle.length; j++) {
        this.tabelHeadeTitle[j].isShow = e;
      }
      let selectLength = 0;
      for (var i = 0; i < that.tabelHeadeTitle.length; i++) {
        if (that.tabelHeadeTitle[i].isShow) {
          selectLength++;
        }
      }
      that.selectLength = selectLength;
    },
    // 合计
    getSummaries(param) {
      let that = this;
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        for (var i = 0; i < that.totalArr.length; i++) {
          if (column.property == that.totalArr[i]) {
            if (!values.every((value) => isNaN(value))) {
              sums[index] = values.reduce((prev, curr) => {
                const value = Number(curr);
                if (!isNaN(value)) {
                  return prev + curr;
                } else {
                  return prev;
                }
              }, 0);
              sums[index] += "";
            } else {
              sums[index] = "---";
            }
          }
        }
      });
      this.$nextTick(() => {
        this.$refs.mytable.doLayout();
      });
      return sums;
    },

    //分页
    handleSizeChange(val) {
      this.size = val;
      this.getData();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getData();
    },
  },
};
</script>

<style scoped lang='scss'>
.box {
  width: 100%;
  display: flex;
}
.boxleft {
  // width: 40%;
  width: 390px;
  min-width: 390px;
  height: 88vh;
  overflow: auto;
  margin-right: 30px;
  // border: 1px solid #d7d7d7;
}
.boxright {
  // width: 70%;
  // flex: 1;
  // height: 500px;
  width: calc(100% - 430px);
}
.tablebut {
  margin-left: 15px;
}
.tableBox {
  height: calc(100% - 260px);
}

.colorStyle {
  color: #f00;
}

.mdTitName {
  height: 24px;
  border: 1px solid #d7d7d7;
  text-align: center;
  min-width: 100px;
}

.queryCriteria {
  .queryItem_one {
    .queryTextarea {
      border: 1px solid #d7d7d7;
    }
  }
  .timeBox {
    border: 1px solid #d7d7d7;
  }
}

.headerbox {
  width: 100%;
  display: flex;
  justify-content: center;
}

.footerbox {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.newForm {
  margin-left: 80px;
  .queryItem1 {
    width: 60%;
    margin-top: 20px;
    display: flex;
    .inputstyle {
      width: 100%;
      display: flex;
    }
  }
}

.HeadInputbox .item_right .singleinp1 .el-date-editor.el-input,
.HeadInputbox .item_right .singleinp1 .el-date-editor.el-input__inner {
  width: 100%;
}

.frominput ::v-deep {
  .el-input__inner {
    height: 30px;
  }
}
</style>